const imgStaticUrl = '/static/images/'
export const imgNetworkUrl ='https://dfosspic01.oss-cn-shenzhen.aliyuncs.com/images/dfinfowebsite/df/'
export const videoNetworkUrl ='https://dfosspic01.oss-cn-shenzhen.aliyuncs.com/video/'
export const ossUrl = 'https://dfosspic01.oss-cn-shenzhen.aliyuncs.com'
// 官网图片地址
export const Ao4qwc = `${imgNetworkUrl}ABUIABACGAAg0-_h7wUoxvzVmQcwlAo4qwc.jpg.webp`
export const Topping = `${imgNetworkUrl}04.png`
export const iwU4YA = `${imgNetworkUrl}ABUIABACGAAg1-mh7wUoq_TpvQEwiwU4YA.jpg.webp`
export const igU4YA = `${imgNetworkUrl}ABUIABACGAAg16Sa7gUo8OXG1gEwigU4YA.jpg.webp`
export const A84qgI = `${imgNetworkUrl}ABUIABACGAAg2qSa7gUo2dXD3wYwgA84qgI.jpg`
export const gY4iwQ = `${imgNetworkUrl}ABUIABACGAAg39-T7gUohLjmngYwjgY4iwQ.jpg.webp`
export const Dzi0Ag = `${imgNetworkUrl}ABUIABACGAAg3KSa7gUon6XjZzCADzi0Ag.jpg`
export const A84vwM = `${imgNetworkUrl}ABUIABACGAAg3aSa7gUoztXYkwUwgA84vwM.jpg`
export const Ao4_gY = `${imgNetworkUrl}ABUIABACGAAg7f6h7wUomKXhjAEwlAo4_gY.jpg.webp`
export const clAcwj = `${imgNetworkUrl}ABUIABACGAAgjN-T7gUo0qnclAcwjgY4iwQ.jpg.webp`
export const go4_wY = `${imgNetworkUrl}ABUIABACGAAgjv_h7wUo6ZLOswQwkgo4_wY.jpg.webp`
export const A84kgU = `${imgNetworkUrl}ABUIABACGAAgn6uj7gUo4PnMswcwgA84kgU.jpg`
export const A84_wY = `${imgNetworkUrl}ABUIABACGAAgnbOi7wUo4P-5_wcwgA84_wY.jpg`
export const PzogUw = `${imgNetworkUrl}ABUIABACGAAgnt3T7gUokrPzogUwjgY4iwQ.jpg.webp`
export const WCjiAw = `${imgNetworkUrl}ABUIABACGAAgoquj7gUo6M30IzCWCji-Aw.jpg.webp`
export const gA84YA = `${imgNetworkUrl}ABUIABACGAAgpI2a7gUozIvb8gQwgA84YA.jpg.jpg.webp`
export const A84pAI = `${imgNetworkUrl}ABUIABACGAAgrfmj7gUo7KrengMwgA84pAI.jpg`
export const A84qwc = `${imgNetworkUrl}ABUIABACGAAgsbOi7wUolOna2QQwgA84qwc.jpg`
export const DzjfAQ = `${imgNetworkUrl}ABUIABAEGAAg0OKg_AUova6xAzCADzjfAQ.png`
export const A84_gY = `${imgNetworkUrl}ABUIABACGAAgirOi7wUo-Oqa0AcwgA84_gY.jpg`
export const public_security_icon = `${imgNetworkUrl}public_security_icon.png`
export const A849z8 = `${imgNetworkUrl}ABUIABACGAAgn9iG8AUogMjg5QQwgA849z8.jpg`
export const A843wE = `${imgNetworkUrl}ABUIABAEGAAgw_Wg_AUo-puZiwIwgA843wE.png`
export const A84ozw = `${imgNetworkUrl}ABUIABACGAAgso797gUotJm0jAUwgA84ozw.jpg`
export const A846wY = `${imgNetworkUrl}ABUIABACGAAgmb_k7gUo9NPIywYwgA846wY.jpg`
export const A84zgc = `${imgNetworkUrl}ABUIABACGAAgmb_k7gUopITq3gYwgA84zgc.jpg`
export const A84_QM = `${imgNetworkUrl}ABUIABACGAAgmb_k7gUoyNuCrwYwgA84-QM.jpg`
export const A84oQc = `${imgNetworkUrl}ABUIABACGAAgmr_k7gUomLW6zQEwgA84oQc.jpg`
export const A84zgY = `${imgNetworkUrl}ABUIABACGAAgmr_k7gUosM-MtgYwgA84zgY.jpg`
export const A842RA = `${imgNetworkUrl}ABUIABACGAAgnb_k7gUoyq_W2gMwgA842RA.jpg`
export const A843wo = `${imgNetworkUrl}ABUIABACGAAgnL_k7gUosJPVnwcwgA843wo.jpg`
export const A840Rk = `${imgNetworkUrl}ABUIABACGAAg38uk7gUokP7rjwEwgA840Rk.jpg`
export const A84tQM = `${imgNetworkUrl}ABUIABACGAAgqvGj7gUo0Ma7kAIwgA84tQM.jpg`
export const A840AM = `${imgNetworkUrl}ABUIABACGAAg1Zik7gUoor7LogYwgA840AM.jpg`
export const A84lQk = `${imgNetworkUrl}ABUIABACGAAgor-u7gUo1sWz8AYwgA84lQk.jpg.webp`
export const A849go = `${imgNetworkUrl}ABUIABACGAAg2J6o7gUoxfrS_AcwgA849go.jpg`
export const A84yQ8 = `${imgNetworkUrl}ABUIABACGAAggeTU7gUoha393gYwgA84yQ8.jpg`
export const A841ww = `${imgNetworkUrl}ABUIABACGAAgnNek7gUo_uPX7AQwgA841ww.jpg`
export const A84zgI = `${imgNetworkUrl}ABUIABACGAAg8P2j7gUozO216AMwgA84zgI.jpg`
export const A845QM = `${imgNetworkUrl}ABUIABACGAAg8P2j7gUozJHTggIwgA845QM.jpg`
export const wgA840 = `${imgNetworkUrl}ABUIABACGAAg8P2j7gUogOacjQEwgA840AM.jpg`
export const A841AI = `${imgNetworkUrl}ABUIABACGAAg8P2j7gUo9aDnlQUwgA841AI.jpg`
export const Jy72QY = `${imgNetworkUrl}ABUIABACGAAg2sTU7gUowJy72QYwxQE4xQE.jpg.webp`
export const pblxgE = `${imgNetworkUrl}ABUIABACGAAg2sTU7gUoipblxgEwxQE4xQE.jpg.webp`
export const O7NywU = `${imgNetworkUrl}ABUIABACGAAg2MTU7gUopO7NywUwxQE4xQE.jpg.webp`
export const samjwQ = `${imgNetworkUrl}ABUIABACGAAg2MTU7gUoxsamjwQwxQE4xQE.jpg.webp`
export const ATjFAQ = `${imgNetworkUrl}ABUIABACGAAg2MTU7gUo3omkMjDFATjFAQ.jpg.webp`
export const vfj_gM = `${imgNetworkUrl}ABUIABACGAAg2MTU7gUoivfj-gMwxQE4xQE.jpg.webp`
export const am2vQQ = `${imgNetworkUrl}ABUIABACGAAg9-PS7gUo6am2vQQwgA849go.jpg`
// 官网视频地址
export const qRyAcVideo = `${videoNetworkUrl}ABUIABA6GAAgwpy36wUow5qRyAc.mp4`

export const home_right_icon = `${imgStaticUrl}home_right_icon`