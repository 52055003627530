<template>
    <div>
        <img v-if="index == 0" :src="A849z8" />
        <div v-if="index == 1 || index == 2 || index == 3" class="Smart-toilet">
            <img v-if="index == 1" :src="A84ozw" alt="智能马桶" />
            <div v-else-if="index == 2">
                <img v-lazy="A846wY" alt="智能腕表1" />
                <img v-lazy="A84zgc" alt="智能腕表2" />
                <img v-lazy="A84_QM" alt="智能腕表3" />
                <img v-lazy="A84oQc" alt="智能腕表4" />
                <img v-lazy="A84zgY" alt="智能腕表5" />
                <img v-lazy="A843wo" alt="智能腕表6" />
                <img v-lazy="A842RA" alt="智能腕表7" />
            </div>
            <img v-else-if="index == 3" :src="A840Rk" alt="智能眼镜" />
            <div class="medical-nav" :style="{top: index == 3 ? '14.1%' : index == 2 ? '6.1%' : ''}">
                <div class="pointer" :class="{'medical-color':index == 1}" @click="$router.push({path: '/medical', query:{index: 1}})">智能马桶</div>
                <div class="medical-nav-line"></div>
                <div class="pointer" :class="{'medical-color':index == 2}" @click="$router.push({path: '/medical', query:{index: 2}})">智能腕表</div>
                <div class="medical-nav-line"></div>
                <div class="pointer" :class="{'medical-color':index == 3}" @click="$router.push({path: '/medical', query:{index: 3}})">智能眼镜</div>
            </div>
        </div>
    </div>
</template>
<script>
import { A849z8, A84ozw, A846wY, A84zgc, A84_QM, A84oQc, A84zgY, A842RA, A843wo, A840Rk } from '@/utils/imgUrl'
export default {
    name: 'medical',
    data(){
        return{
            index: 0
        }
    },
    computed:{
        A849z8: () => A849z8,
        A84ozw: () => A84ozw,
        A846wY: () => A846wY,
        A84zgc: () => A84zgc,
        A84_QM: () => A84_QM,
        A84oQc: () => A84oQc,
        A84zgY: () => A84zgY,
        A842RA: () => A842RA,
        A843wo: () => A843wo,
        A840Rk: () => A840Rk,
    },
    created(){
        if(this.$route.query.index){
            this.index = this.$route.query.index
        }
    },
    watch:{
        $route(to,from){
            this.index = to.query.index
        }
    }
}
</script>
<style lang="scss" scoped>
.Smart-toilet{
    position: relative;
    img{
        display: block;
    }
}
.medical-nav{
    position: absolute;
    width: 1300px;
    // height: 70px;
    top: 5.98%;
    left: calc((100% - 1300px) / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #15f2db;
    font-size: 20px;
    font-weight: bold;
}
.medical-nav div{
    margin-right: 90px;
}
.medical-nav div:last-child{
    margin-right: 0;
}
.medical-nav-line{
    width: 2px;
    height: 20px;
    background: #15f2db;
}
.medical-color{
    color: #fff;
}
</style>